<template>
  <div class="view-signEvidence">
    <div class="header" v-if="EvidenceList && EvidenceList.length">
      <div class="inner" @click="onSelect">
        <div class="text">
          {{ EvidenceList[active].contractFileInfo.fileName }}
        </div>
        <div class="icon" :class="isShowSelect ? 'icon2' : ''"></div>
      </div>
      <ul
        class="select"
        :style="{
          opacity: isShowSelect ? 1 : 0,
          maxHeight: isShowSelect ? '140px' : 0,
        }"
      >
        <li
          class="select-item"
          v-for="(item, index) in EvidenceList"
          :key="index"
          :class="active === index ? 'active-item' : ''"
          @click="onChange(index)"
        >
          {{ item.contractFileInfo.fileName }}
        </li>
      </ul>
    </div>
    <!-- v-if="EvidenceList && EvidenceList.length" -->
    <div class="file-content" v-if="false">
      <template v-if="EvidenceList[active].contractFileInfo">
        <div class="file-type">
          <div class="detail">
            文件ID号：{{ EvidenceList[active].contractFileInfo.fileNo }}
          </div>
          <div class="detail">
            {{ systemInfo.company }}编号：{{
              EvidenceList[active].contractFileInfo.contractNo
            }}
          </div>
          <div
            class="detail"
            v-if="EvidenceList[active].contractFileInfo.contractEvidenceNo"
          >
            区块链编号：{{
              EvidenceList[active].contractFileInfo.contractEvidenceNo
            }}
          </div>
          <div
            class="detail"
            v-if="EvidenceList[active].contractFileInfo.contractEvidenceNo"
          >
            区块链编号：{{
              EvidenceList[active].contractFileInfo.contractEvidenceNo
            }}
          </div>
          <div class="tag">
            声明：此页为{{
              systemInfo.company
            }}系统生成，用于记录文件历史信息，不作为正式条款的一部分
          </div>
        </div>
        <div class="file-title">文件详细信息</div>
        <ul class="file-table">
          <li class="item">
            <div class="label">文件名称</div>
            <div class="value">
              {{ EvidenceList[active].contractFileInfo.fileName }}
            </div>
          </li>
          <li class="item">
            <div class="label">发起人</div>
            <div class="value">
              {{ EvidenceList[active].contractFileInfo.launchSigner }}
            </div>
          </li>
          <li class="item">
            <div class="label">发起时间</div>
            <div class="value">
              {{
                getTime(EvidenceList[active].contractFileInfo.launchTime * 1000)
              }}
            </div>
          </li>
          <li class="item">
            <div class="label">文件页数</div>
            <div class="value">
              {{ EvidenceList[active].contractFileInfo.pageNum }}
            </div>
          </li>
          <li class="item">
            <div class="label">签署人</div>
            <div class="value">
              {{ EvidenceList[active].contractFileInfo.signerCnt }}人
            </div>
          </li>
          <li class="item">
            <div class="label">完成时间</div>
            <div class="value">
              {{
                getTime(EvidenceList[active].contractFileInfo.finishTime * 1000)
              }}
            </div>
          </li>
          <template v-if="!EvidenceList[active].contractFileInfo.isInvalid">
            <li class="item">
              <div class="label">发起作废文件时间</div>
              <div class="value">
                {{ EvidenceList[active].contractFileInfo.invalidTime }}
              </div>
            </li>
            <li class="item">
              <div class="label">作废完成时间</div>
              <div class="value">
                {{ EvidenceList[active].contractFileInfo.invalidFinishTime }}
              </div>
            </li>
          </template>
        </ul>
      </template>
      <div class="file-title">签署信息</div>
      <div
        class="file-card"
        v-for="(item, index) in EvidenceList[active].certInfoList"
        :key="index"
        :class="'file-card' + item.comRole"
      >
        <div class="cardContent">
          <div>使用者：{{ item.signerName }}</div>
          <div>颁发者：{{ item.certOrg }}</div>
          <div>有效期：{{ getDay(item.certValidTime * 1000) }}</div>
          <div>序列号：{{ item.certNo }}</div>
        </div>
      </div>
      <div class="file-title">签署事件</div>
      <van-steps direction="vertical" :active="0" class="signRecordForm">
        <van-step
          v-for="(item, index) in EvidenceList[active].signRecordList"
          :key="index"
        >
          <div class="item">
            <div class="left">
              <div class="name">{{ item.operateEvent }}</div>
              <div class="info">
                {{ item.operatorName }}
              </div>
            </div>
            <div class="right">
              <van-icon name="clock-o" />
              {{ getTime(item.operateTime * 1000) }}
            </div>
          </div>
        </van-step>
      </van-steps>
    </div>
    <div class="file-content" v-if="EvidenceList[active]">
      <img
        v-for="(item, index) in EvidenceList[active].imageList"
        :key="index"
        :src="item"
        class="file-img"
      />
    </div>
    <div class="sign-signEvidence-footer">
      <van-button class="btn-large" type="primary" @click="handleToDownload"
        >下载凭证报告</van-button
      >
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import Apis from '@/api/contractSign'
  import { Dialog, Toast } from 'vant'
  import { isWeiXin } from '@/utils/ua'
  import moment from 'moment'

  export default {
    data() {
      return {
        active: 0,
        businessId: null,
        isShowSelect: false,
        EvidenceList: [],
      }
    },
    created() {
      this.businessId = this.$route.query.businessId
      this.getEvidenceList()
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
      }),
    },
    methods: {
      getDay(timeStamp) {
        return moment(timeStamp).format('YYYY/MM/DD')
      },
      getTime(timeStamp) {
        return moment(timeStamp).format('YYYY/MM/DD HH:mm:ss')
      },
      async getEvidenceList() {
        try {
          this.toast = Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
            duration: 200000,
          })
          const obj = {
            businessId: this.businessId,
          }
          await Apis.evidenceList(obj)
            .then(res => {
              this.EvidenceList = res
            })
            .catch(err => {
              console.error(err)
            })
            .finally(() => {
              this.toast.clear()
            })
        } catch (error) {
          console.error(error)
        }
      },
      onSelect() {
        this.isShowSelect = !this.isShowSelect
      },
      onChange(index) {
        this.active = index
        this.onSelect()
      },
      handleToDownload() {
        try {
          Apis.getDownloadEvidenceUrl({
            businessId: this.businessId,
          }).then(res => {
            this.copyUrl = res
            if (isWeiXin()) {
              Dialog.confirm({
                title: '下载合同',
                message: '复制链接到浏览器打开，然后下载',
                confirmButtonText: '复制链接',
              })
                .then(() => {
                  this.$copyText(this.copyUrl)
                  this.$toast({
                    message:
                      '合同链接已复制成功，请前往浏览器打开，推荐使用百度，某些浏览器下载后可能会出现打不开的问题',
                  })
                })
                .catch(() => {
                  // on cancel
                })
              return
            }
            Dialog.confirm({
              title: '提示',
              message: '您要下载凭证报告吗？',
            })
              .then(() => {
                const link = document.createElement('a')
                link.href = this.copyUrl
                link.click()
              })
              .catch(() => {
                // on cancel
              })
          })
        } catch (error) {
          console.error(error)
        }
      },
    },
  }
</script>

<style lang="less" scoped>
  .view-signEvidence {
    padding-bottom: 60px;
    .header {
      width: 375px;
      height: 54px;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      .select {
        position: absolute;
        transition: all 0.4s;
        top: 48px;
        left: 16px;
        width: 343px;
        z-index: 999;
        // height: 130px;
        background: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 6px 10px 0px rgba(230, 234, 237, 0.3);
        overflow: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: auto !important;
        .select-item {
          width: 343px;
          padding: 10px;
          // height: 40px;
          background: #fff;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          // text-align: center;
          color: #5f6368;
          // line-height: 40px;
          white-space: nowrap;
          word-break: break-all;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .active-item {
          background-color: #ebf3ff;
        }
      }
      /* 默认显示滚动条 */
      .select::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 4px;
      }
      .select::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #d8d8d8;
      }
      .inner {
        width: 343px;
        height: 32px;
        background: #f4f5f6;
        border-radius: 16px;
        padding: 0 16px;
        line-height: 32px;
        display: flex;
        align-items: center;
        .text {
          height: 16px;
          font-size: 12px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #111a34;
          line-height: 16px;
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .icon {
          margin-top: 5px;
          border: 5px solid #434751;
          border-color: #434751 transparent transparent transparent;
        }
        .icon2 {
          margin-top: -5px;
          border-color: transparent transparent #434751 transparent;
        }
      }
    }
    .file-content {
      // padding: 0 16px 16px;
      .file-img {
        width: 100%;
      }
      .file-title {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: bold;
        text-align: left;
        color: #677283;
        line-height: 20px;
        margin-bottom: 16px;
      }
      .file-type {
        width: 343px;
        background: #f6f9ff;
        border-radius: 4px;
        margin-bottom: 16px;
        padding: 14px;

        .detail {
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          text-align: left;
          color: #434751;
          line-height: 20px;
          margin-bottom: 8px;
          word-break: break-all;
        }
        .tag {
          font-size: 12px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #677283;
          line-height: 20px;
        }
      }
      .file-table {
        border: 1px solid #f0f0f0;
        border-radius: 4px 4px 0px 0px;
        margin-bottom: 16px;
        .item {
          box-sizing: border-box;
          padding: 16px;
          display: flex;
          align-items: center;
          font-size: 13px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #6d7790;
          line-height: 18px;
          .label {
            color: #6d7790;
            width: 71px;
          }
          .value {
            flex: 1;
            color: #111a34;
          }
        }
        .item:nth-child(odd) {
          background-color: #f8f8f8;
        }
        .item:nth-child(even) {
          background-color: #ffffff;
        }
      }
      .file-card {
        position: relative;
        width: 343px;
        height: 137px;
        background: url('../../assets/imgs/signFile/fileCard1.png') no-repeat
          center;
        background-size: 100%;
        margin-bottom: 16px;
        .cardContent {
          position: absolute;
          top: 33px;
          left: 130px;
          width: 137px;
          height: 70px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          div {
            width: 100%;
            font-size: 7px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            color: #677283;
            line-height: 9px;
            margin-bottom: 3px;
          }
        }
      }
      .file-card0 {
        background: url('../../assets/imgs/signFile/fileCard1.png') no-repeat
          center;
        background-size: 100%;
      }
      .file-card1 {
        background: url('../../assets/imgs/signFile/fileCard2.png') no-repeat
          center;
        background-size: 100%;
      }
      .signRecordForm {
        overflow: visible;
        padding-left: 20px;
        /deep/ .van-steps__items {
          /deep/ .van-step__circle-container {
            /deep/ .van-step__circle {
              width: 16px;
              height: 16px;
              background: url('../../assets/imgs/signFile/circle.png') no-repeat
                center;
              background-size: 100%;
            }
            /deep/ .van-step__icon--active {
              width: 16px;
              height: 16px;
              background: url('../../assets/imgs/signFile/circleActive.png')
                no-repeat center;
              background-size: 100%;
            }
            /deep/ .van-step__icon--active:before {
              content: '';
            }
          }
          /deep/ .van-step__line {
            background-color: rgba(185, 185, 185, 0.22);
          }
        }
        .item {
          background: #ffffff;
          border: 1px solid #f0f0f0;
          border-radius: 4px;
          box-sizing: border-box;
          padding: 11px 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #9c9c9c;
          .left {
            .name {
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: left;
              line-height: 20px;
              margin-bottom: 7px;
            }
            .info {
              font-size: 12px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: left;
              line-height: 17px;
            }
          }
          .right {
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            line-height: 17px;
            display: flex;
            align-items: center;
            .van-icon {
              margin-right: 5px;
            }
          }
        }
        /deep/ .van-step__title--active {
          .item {
            .left {
              .name {
                color: #111a34;
              }
              .info {
                color: #677283;
              }
            }
            .right {
              color: #677283;
            }
          }
        }
      }
    }
    .sign-signEvidence-footer {
      position: fixed;
      width: 100%;
      left: 0;
      bottom: 0;
      background: @WHITE;
      box-shadow: 0 -6px 10px 0 rgba(230, 234, 237, 0.3);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 16px calc(@safe-area + 10px);
      z-index: 99;
      .footer-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 10px;
        margin-right: 18px;
        .icon-btn {
          font-size: 16px;
        }
      }
      .van-button {
        transition: all 0.3s;
        flex: 1;
        height: 44px;
        padding: 0;

        &.btn-large {
          width: 100%;
        }

        .van-button__text {
          font-size: 16px;
          font-weight: 500;
        }
      }

      .van-button + .van-button {
        margin-left: 10px;
      }
    }
  }
</style>
